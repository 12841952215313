import React from "react"
import { CenterContent, GalleryItem } from "../components/_index"
import { useMediaQuery, useTheme } from "@material-ui/core"
import * as PropTypes from "prop-types"
import { graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"
import SiteMetadata from "../components/SiteMetaData"
import Layout from "../components/Layout"

const GalleryItemTemplate = (props) => {
  const {
    data: { galleryItem },
  } = props

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Layout>
      <SiteMetadata title={galleryItem.title} description={galleryItem.title} />
      <CenterContent
        ml={matches ? theme.spacing(2) : 0}
        mr={matches ? theme.spacing(2) : 0}
        mt={0}
        pt={0}
      >
        {
          <Grid item sm={10}>
            <GalleryItem {...galleryItem} />
          </Grid>
        }
      </CenterContent>
    </Layout>
  )
}

GalleryItemTemplate.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query($id: String) {
    galleryItem: contentfulGalleryItem(contentful_id: { eq: $id }) {
      id: contentful_id
      title
      category
      hashTags
      images {
        fluid(maxWidth: 1050, quality: 85) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
      }
    }
  }
`

export default GalleryItemTemplate
